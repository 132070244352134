.game {
    flex-direction: column;
    display: flex;
    height: 100%;

    .container {
        position: relative;
        padding-top: 10rem;

        &::before {
            content: '';
            background: url('../../assets/robot.png') no-repeat top center;
            background-size: cover;
            position: absolute;
            height: 800px;
            width: 600px;
            left: -9rem;
            top: 8rem;
        }

        h1 {
            margin-bottom: 2rem;
            text-align: center;
            font-size: 3rem;
            color: white;
        }

        .card {
            border: 1px solid #d3d3d3 !important;
            background: #f9f9f9 !important;
            color: #000 !important;
            margin: auto;
            height: 74px;
            width: 300px;
    
            input {
                border: 2px solid #c1c1c1;
                background-color: #fff;
                border-radius: 2px;
                margin-top: .5rem;
                cursor: pointer;
                height: 24px;
                width: 24px;
            }
    
            p {
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                margin: 0;
            }
        }
    }
}