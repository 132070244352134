.recaptcha {
    border: 2px solid #d3d3d3 !important;
    background: #f9f9f9 !important;
    transition: all .3s ease-in-out;
    color: #000 !important;
    inset: 4rem 0 auto 0;
    position: absolute;
    max-width: 920px;
    padding: 1rem;
    z-index: -10;
    margin: auto;
    opacity: 0;

    &.show {
        z-index: 99;
        opacity: 1;
    }

    p {
        font-size: 1.2rem;
    }
}