.congratulations {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    display: flex;
    height: 100%;

    .container {
        position: relative;

        &::before {
            content: '';
            background: url('../../assets/dog.png') no-repeat top center;
            background-size: cover;
            position: absolute;
            height: 600px;
            width: 420px;
            left: -11rem;
            top: 9rem;
        }

        p {
            margin-bottom: 2rem;
            font-size: 2rem;
            color: white;
        }
    
        h1 {
            font-size: 3rem;
            color: white;
    
            span {
                text-decoration: underline;
                color: yellow;
            }
        }
    
        .code {
            font-family: 'Roboto', sans-serif;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 4rem;
            color: white;
            margin: 2rem 0;
    
            img {
                margin-left: 3rem;
            }
        }
    
        small {
            font-size: 2rem;
            color: white;
        }

        .btn {
            font-size: 1.5rem;
        }
    }
}