.home {
    background: url('../../assets/home-background.jpg') no-repeat center center;
    justify-content: flex-end;
    flex-direction: column;
    background-size: cover;
    text-align: center;
    display: flex;
    height: 100%;

    .container {
        padding-bottom: 9rem;

        h1 {
            font-size: 3.5rem;
            color: white;
        }
    
        .btn {
            font-size: 1.5rem;
            margin-top: 1rem;
        }
    }
}